<template>
    <form @submit.prevent="onSubmit" validate class="my-xxl s-up:w-10 m-up:w-8 l-up:w-6 xl-up:w-5">
        <fieldset>
            <label for="name">Naam</label>
            <input type="text" id="name" name="name" v-model="form.name" required autocomplete="name" />
        </fieldset>

        <fieldset>
            <label for="email">E-mail</label>
            <input type="email" id="email" name="email" v-model="form.email" required autocomplete="email" />
        </fieldset>

        <fieldset>
            <label for="phone">Telefoonnummer</label>
            <input type="tel" id="phone" name="phone" v-model="form.phone" required autocomplete="tel" />
        </fieldset>

        <fieldset>
            <label for="subject" class="justify-between align-end">
                <div>{{ subjectLabel }}</div>
                <span v-if="subjectLabel === 'Onderwerp'" class="color-tertiary size-7">Optioneel</span>
            </label>
            <input type="text" id="subject" name="subject" v-model="form.subject" :required="subjectLabel !== 'Onderwerp'" />
        </fieldset>

        <fieldset v-if="subjectLabel !== 'Onderwerp'">
            <label for="installation_date">Installatiedatum</label>
            <input type="date" id="installation_date" name="installation_date" v-model="form.installation_date" />
        </fieldset>

        <fieldset>
            <label for="message">Bericht</label>
            <textarea id="message" name="message" v-model="form.message" required />
        </fieldset>

        <button type="submit" class="bg-accent" :loading="loading">Verzenden</button>
    </form>
</template>

<script>
export default {
    name: 'Contact',
    props: {
        name: String,
        subjectLabel: {
            type: String,
            default: 'Onderwerp',
        },
    },
    data() {
        return {
            form: {
                name: '',
                email: '',
                phone: '',
                installation_date: '',
                message: '',
                subject: '',
            },
            loading: false,
        };
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
    methods: {
        onSubmit() {
            this.loading = true;
            const url = '/senddocument/';
            const data = this.createMailContent(this.form);
            this.$http.post(url, data)
                .then(() => {
                    this.$router.push({
                        name: 'ContactSuccess',
                    });
                }).catch((err) => {
                    this.loading = false;
                    console.error(err);
                });
        },

        createMailContent(data) {
            const content = {
                id: 'send',
                to: [this.data.settings[0].company_email],
                subject: `BedrijfsBelang ${this.name} - ${data.subject}`,
            };
            let installdate = '';
            if (data.installation_date) {
                const dateparts = data.installation_date.split('-');
                installdate = `${dateparts[2]}-${dateparts[1]}-${dateparts[0]}`;
            }
            content.message = `${data.name} heeft het ${this.name} op de BedrijfsBelang website ingevuld.\n
                Emailadres: ${data.email}
                ${data.phone ? `Telefoonnummer: ${data.phone}` : ''}
                ${installdate ? `Installatiedatum: ${installdate}` : ''}
                ${data.message}
            `;

            return content;
        },
    },
};
</script>
