<template>
    <div class="c w-xl content">
        <div class="accentlink s-up:w-10 m-up:w-8 l-up:w-6 xl-up:w-5">
            <h2 class="w-10">{{ data.contact_form_success_title_nl }}</h2>
            <div class="color-secondary">
                <span v-html="data.contact_form_success_text_nl" class="mr-s" />
                <router-link :to="{ name: 'Home' }">Terug naar home &raquo;</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
};
</script>
