<template>
    <Layout v-if="mode === 'form' || mode === 'talk'">
        <template v-slot:top>
            <div class="s-up:w-10 m-up:w-8 l-up:w-6 xl-up:w-5 mb-xxl mt-xxl">
                <h2 class="w-10">{{ mode === 'talk' && data.contact_form_talk_title_nl ? data.contact_form_talk_title_nl : data.contact_form_title_nl }}</h2>
                <div class="color-secondary">
                    <span v-html="mode === 'talk' && data.contact_form_talk_text_nl ? data.contact_form_talk_text_nl : data.contact_form_text_nl" />
                </div>
            </div>
        </template>
        <template v-slot:content>
            <form-component name="Contactformulier" />
        </template>
        <template v-slot:sidebar>
            <Sidebar type="contact" />
        </template>
    </Layout>
    <div v-else-if="mode === 'scan'" id="gmu-content-container" />
    <div v-else class="c w-xl content relative">
        <div class="s-up:w-10 m-up:w-8 l-up:w-6 xl-up:w-5 mb-xxl mt-xxl">
            <h2 class="w-10">{{ data.contact_choose_title_nl }}</h2>
            <div class="color-secondary">
                <span v-html="data.contact_choose_text_nl" />
            </div>
            <div class="flex flex-wrap mt-xxl">
                <Button class="bg-accent mb-r mr-r ml-0" @click="setmode('scan')">{{ data.contact_choose_button_seed_nl }}</Button>
                <Button class="bg-accent color-accent -outline mb-r mr-r ml-0" @click="setmode('form')">{{ data.contact_choose_button_form_nl }}</Button>
                <Button v-if="data.contact_choose_button_talk_nl" class="bg-blue ml-0" @click="setmode('talk')">{{ data.contact_choose_button_talk_nl }}</Button>
            </div>
        </div>
    </div>
</template>

<script>
import Layout from '@/components/Layout-Sidebar.vue';
import Sidebar from '@/components/Sidebar.vue';
import FormComponent from '@/components/Form.vue';

export default {
    name: 'Contact',
    props: {
        initialmode: {
            type: String,
            default: '',
        },
    },
    components: {
        Sidebar,
        Layout,
        FormComponent,
    },
    data() {
        return {
            form: {
                name: '',
                email: '',
                phone: '',
                message: '',
                subject: '',
            },
            mode: '',
            loading: false,
        };
    },
    created() {
        if (this.initialmode) { this.mode = this.initialmode; }
        if (window.PRERENDER_INJECTED) {
            this.mode = 'form';
        }
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
    methods: {
        onModeChange(val) {
            if (val === 'scan') {
                const plugin = document.createElement('script');
                plugin.setAttribute(
                    'src',
                    '//app.2solar.nl/local/javascript/gmu.js?key=5a8e9d28a6603&code=5ed8f90cbd00f',
                );
                plugin.async = true;
                document.head.appendChild(plugin);
            }
        },

        setMeta(r) {
            if (r && r.id) {
                this.$store.commit('setMeta', {
                    data: {
                        title: r.contact_form_title_nl,
                        titleDefault: `${process.env.VUE_APP_NAME}`,
                        description: r.contact_form_text_nl.substr(0, 100),
                    },
                });
            }
        },

        setmode(mode) {
            this.mode = mode;

            let gaMode = '';
            if (mode === 'scan') { gaMode = '2solar'; } else if (mode === 'form') { gaMode = 'native'; } else if (mode === 'talk') { gaMode = 'native-talk'; }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'choose_contactform',
                form: gaMode,
            });
        },
    },
    watch: {
        mode: {
            handler: 'onModeChange',
            immediate: true,
        },

        data: {
            handler: 'setMeta',
            immediate: true,
        },
    },
};
</script>
